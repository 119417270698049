body {
  margin: 0;
  font-family: Roboto, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131313;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-container {
  background: transparent;
  transform-style: preserve-3d;
  perspective: 500px;
}

.card-front,
.card-back {
  backface-visibility: hidden;
}

.card-front {
  position: absolute;
}

.card-back {
  transform: rotateY(180deg);
}

.front-face-transition-enter {
  transform: rotateY(180deg);
}
.front-face-transition-enter-active {
  transition: all 1000ms;
  transform: rotateY(0deg);
}
.front-face-transition-enter-done {
  transform: rotateY(0deg);
}

.front-face-transition-exit {
  transform: rotateY(0deg);
}

.front-face-transition-exit-active {
  transform: rotateY(180deg);
  transition: all 1000ms;
}

.front-face-transition-exit-done {
  transform: rotateY(180deg);
}

.back-face-transition-enter {
  transform: rotateY(-180deg);
}
.back-face-transition-enter-active {
  transform: rotateY(0deg);
  transition: all 1000ms;
}
.back-face-transition-enter-done {
  transform: rotateY(0deg);
}

.back-face-transition-exit {
  transform: rotateY(0deg);
}

.back-face-transition-exit-active {
  transform: rotateY(-180deg);
  transition: all 1000ms;
}

.back-face-transition-exit-done {
  transform: rotateY(-180deg);
}